<template>
  <v-container class="px-0 mx-0 pa-0" fluid>
    <v-card flat class="ma-0 pa-0">
      <v-card-text class="ma-0 pa-0">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" class="text-right justify-end">
            <h5>
              {{ currentCheckedIn }} {{ $t("eventbee.lang_currentCheckIn") }}
              <v-btn color="error" @click="clearAll" :disabled="currentCheckedIn<1">{{ $t('generic.lang_clearAll') }}</v-btn>
            </h5>
          </v-col>
          <v-col class="pt-0 mt-0" cols="12">
            <v-row class="pt-0 mt-0">
              <v-col cols="12" lg="4" md="6" sm="12">
                <b-form-select :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']" v-model="type"
                  :label-field="$t('generic.lang_allTypes')" :placeholder="$t('generic.lang_allTypes')" size="md">
                  <b-form-select-option :value="-1">
                    {{ $t("generic.lang_allTypes") }}
                  </b-form-select-option>
                  <b-form-select-option :value="0">
                    {{ $t("eventbee.lang_entryTicket") }}
                  </b-form-select-option>
                  <b-form-select-option :value="1">
                    {{ $t("eventbee.lang_eventTicket") }}
                  </b-form-select-option>
                </b-form-select>
              </v-col>

              <v-col cols="12" lg="4" md="6" sm="12">
                <b-form-select :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']" v-model="group"
                  :label-field="$t('eventbee.lang_eventGroup')" :placeholder="$t('eventbee.lang_eventGroup')" size="md">
                  <b-form-select-option :value="-1">
                    {{
                    $t("generic.lang_all") +
                    " " +
                    $t("eventbee.lang_eventGroups")
                    }}
                  </b-form-select-option>
                  <b-form-select-option v-for="t in this.eventGroups" :key="t.id" :value="t.id">
                    {{ t.name }}
                  </b-form-select-option>
                </b-form-select>
              </v-col>

              <v-col cols="12" lg="4" md="6" sm="12">
                <b-form-select :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']" v-model="provider"
                  :placeholder="$t('generic.lang_provider')" se size="md">
                  <b-form-select-option :value="-1">
                    {{
                    $t("generic.lang_all") + " " + $t("generic.lang_provider")
                    }}
                  </b-form-select-option>
                  <b-form-select-option v-for="t in this.serviceProviders" :key="t.userID" :value="t.userID">
                    {{ t.fullName }}
                  </b-form-select-option>
                </b-form-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="pa-0" cols="12">
            <v-data-table dense :headers="datatableHeaders" :items="checkedinCustomers" :loading="loading"
              :no-data-text="$t('generic.lang_noEntriesFound')">
              <!--headers-->
              <template v-slot:header.date="{ header }">
                <font-awesome-icon :icon="['fal', 'calendar']" class="text-muted" size="2x" style="font-size: 15px" />
                <span class="ml-2">{{ header.text }}</span>
              </template>

              <template v-slot:header.customer="{ header }">
                <font-awesome-icon :icon="['fal', 'user']" class="text-muted" size="2x" style="font-size: 15px" />
                <span class="ml-2">{{ header.text }}</span>
              </template>

              <template v-slot:item.date="{ item }">
                <div class="date">
                  <span class="binds"></span>
                  <span class="month">{{ item.eventDate }}</span>
                  <span class="month">{{ item.date }}</span>
                  <span class="day text-muted font-weight-bolder">
                    <span class="mx-1">
                      <font-awesome-icon :icon="['fal', 'users']" class="text-muted" size="2x"
                        style="font-size: 16px" />
                      <span class="ml-1" style="font-size: 10px">
                        {{ item.numberOfPersons }}
                      </span>
                    </span>

                    <span class="mx-1">
                      <font-awesome-icon :icon="['fal', 'user']" class="success--text" size="2x"
                        style="font-size: 16px" />
                      <span class="ml-1 success--text" style="font-size: 10px">
                        {{ item.numberOfPersons }}
                      </span>
                    </span>

                    <span class="mx-1">
                      <font-awesome-icon :icon="['fal', 'keynote']" class="text-muted" size="2x"
                        style="font-size: 16px" />
                    </span>
                  </span>
                </div>
              </template>

              <template v-slot:item.customer="{ item }">
                <div v-if="item.customer && !Array.isArray(item.customer)">
                  <strong class="primary--text">
                    {{
                    item.customer.first_name + " " + item.customer.last_name
                    }}
                  </strong>
                  <br />
                  <font-awesome-icon :icon="['fal', 'phone']" class="text-muted" style="font-size: 15px" />
                  <span class="text--black ml-1">
                    {{ item.customer.telPrivate }}
                  </span>
                </div>
                <strong v-else class="primary--text">
                  {{ $t("generic.lang_table_Tresenverkau") }}
                </strong>

                <br />
                <span class="text--black ml-1 font-weight-bold">
                  {{ item.note }}
                </span>

                <div v-if="item.code" class="copy" @click="copy(item.code)">
                  <font-awesome-icon :icon="['fal', 'copy']" class="text-muted" size="2x" style="font-size: 14px" />
                  <span class="font-weight-bold ml-1">{{ item.code }}</span>
                </div>
              </template>

              <template v-slot:item.eventName="{ item }">
                {{ item.eventName }}<br />
                <strong class="text-muted" v-html="item.attribute"></strong>
              </template>

              <template v-slot:item.group="{ item }">
                <strong>{{ item.group }}</strong>
              </template>

              <template v-slot:item.checkedIn="{ item }">
                <span v-if="item.checkedIn">
                  <font-awesome-icon :icon="['fal', 'clock']" class="text-muted" size="2x" style="font-size: 16px" />
                  <span class="font-weight-bold ml-2">
                    {{ item.checkedIn }}
                  </span>
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
        :layout="touchKeyboard.layout" :options="touchKeyboard.options" class="internalWidthExpanded" />
    </div>
  </v-container>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";

import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAt,
  faCalendar,
  faCalendarAlt,
  faCalendarEdit,
  faCalendarTimes,
  faClock,
  faCopy,
  faEdit,
  faKeynote,
  faPhone,
  faUser,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";

library.add(
  faUsers,
  faClock,
  faKeynote,
  faAt,
  faCalendarAlt,
  faCalendar,
  faCalendarEdit,
  faCalendarTimes,
  faUser,
  faPhone,
  faCopy,
  faEdit
);

export default {
  name: "EventBeeCurrentChekedinComponent",
  mixins: [mixin],
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  data() {
    return {
      ENDPOINTS,
      loading: false,
      currentCheckedIn: 0,
      checkedinCustomers: [],
      eventGroups: [],
      serviceProviders: [],
      type: -1,
      group: -1,
      provider: -1,
    };
  },
  computed: {
    datatableHeaders() {
      return [
        {
          text: this.$t("generic.lang_date"),
          value: "date",
        },
        {
          text: this.$t("generic.lang_name"),
          value: "customer",
          sortable: false,
        },
        {
          text: this.$t("generic.lang_event"),
          value: "eventName",
          sortable: false,
        },
        {
          text: this.$t("generic.lang_group"),
          value: "group",
        },
        {
          text: this.$t("eventbee.lang_checkedInAt"),
          value: "checkedIn",
        },
      ];
    },
    excelColumns() {
      return [
        {
          text: this.$t("generic.lang_date"),
          field: "date",
        },
        {
          text: this.$t("generic.lang_name"),
          field: "customer",
        },
        {
          text: this.$t("generic.lang_event"),
          field: "eventName",
        },
        {
          text: this.$t("generic.lang_group"),
          field: "group",
        },
        {
          text: this.$t("eventbee.lang_checkedInAt"),
          field: "checkedIn",
        },
      ];
    },
  },
  watch: {
    type(val) {
      this.loadCheckedinCustomers();
    },
    group(val) {
      this.loadCheckedinCustomers();
    },
    provider(val) {
      this.loadCheckedinCustomers();
    },
  },
  mounted() {
    this.loadCheckedinCustomers();
    this.getEventGroups();
    this.getServiceProviders();
  },
  methods: {
    getEventGroups() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.EVENTGROUPS.GET, {})
        .then((res) => {
          this.eventGroups = res.data.data;
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getServiceProviders() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.SERVICEPROVIDERS, {})
        .then((res) => {
          this.serviceProviders = res.data.users;
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearAll(){
      this.$swal({
        title: this.$t('generic.lang_warning'),
        text: this.$t('generic.lang_clearAllEntriesMSG'),
        icon: "warning",
        confirmButtonText: this.$t('generic.lang_ok'),
        cancelButtonText: this.$t('generic.lang_no'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.clearCheckedIn();
        },
        allowOutsideClick: () => false,
      });
    },
    clearCheckedIn() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.ORDERS.CLEARCHECKEDIN)
        .then((res) => {
          if(res.data.status === 'success'){
            this.loadCheckedinCustomers();
          } else {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadCheckedinCustomers() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.ORDERS.CHECKEDIN, {
          type: this.type,
          groupID: this.group,
          providerID: this.provider,
        })
        .then((res) => {
          if (res.data.status === "success") {
            this.checkedinCustomers = [];
            this.currentCheckedIn = res.data.currentCheckedIn;
            res.data.data.forEach((order) => {
              this.checkedinCustomers.push({
                date: `${order.fromTime.substring(0, 5)} ${this.$t("generic.lang_to")} ${order.toTime.substring(0, 5)}`,
                customer: order.customerData ?? {},
                eventDate: order.eventDate,
                eventName: order.eventName,
                group: order.groupName,
                attribute: order.attribute,
                tickets: order.tickets,
                total: order.total,
                payment: order.paymentType,
                numberOfPersons: order.numberOfPersons,
                created_at: order.created,
                checkedIn: order.checkedIn,
                invoiceUUID: order.invoiceUUID,
                canceled_at:
                  order.canceled > 0
                    ? this.$moment.unix(order.canceled).format("DD.MM.YY HH:MM")
                    : null,
                code: order.code,
                note: order.note,
                uuid: order.uuid,
              });
            });
            this.$emit("reloadStatistics");
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.date {
  display: block;
  width: 160px;
  height: 90px;
  margin: 1px 1px 1px 1px;
  background: #fff;
  text-align: center;
  font-family: "Helvetica", sans-serif;
  position: relative;
}

.date .binds {
  position: absolute;
  height: 15px;
  width: 150px;
  background: transparent;
  border: 2px solid #999;
  border-width: 0 5px;
  top: -6px;
  left: 0;
  right: 0;
  margin: auto;
}

.date .month {
  background: #555;
  display: block;
  padding: 4px 0;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 2px solid #333;
  box-shadow: inset 0 -1px 0 0 #666;
}

.date .day {
  display: block;
  margin: 0;
  padding: 5px 0;
  height: 35px;
  box-shadow: 0 0 3px #ccc;
  position: relative;
}

.date .day .time {
  font-size: 20px;
}

.date .day::after {
  content: "";
  display: block;
  height: 100%;
  width: 96%;
  position: absolute;
  top: 3px;
  left: 2%;
  z-index: -1;
  box-shadow: 0 0 3px #868484;
}

.date .day::before {
  content: "";
  display: block;
  height: 100%;
  width: 95%;
  position: absolute;
  top: 6px;
  left: 0%;
  z-index: -1;
  box-shadow: 0 0 3px #ccc;
}

.copy,
.actions {
  cursor: pointer;
}

.v-toolbar__content {
  padding: 0 !important;
}

#pdfcontainer {
  width: 102% !important;
  margin-left: -13px !important;
  padding: 5px !important;
  padding-top: 0 !important;
}
</style>
