<template>
  <v-container
    class="px-0 mx-0 pa-0"
    fluid
  >
    <v-card class="ma-0 pa-0">
      <v-card-text class="ma-0 pa-0">
        <v-row class="pa-0 ma-0">
          <v-col cols="12">
            <div class="pa-0 px-0 ma-0">
              <v-btn
                :loading="this.downloadingPdf"
                :disabled="this.downloadingPdf"
                class="elevation-0 pl-2 pr-2"
                color="warning"
                outlined
                @click="generatePDF"
              >
                <v-icon small> picture_as_pdf </v-icon>
                {{ $t('erp.lang_print') }}
              </v-btn>
              <v-btn
                class="mr-0"
                color="grey darken-2"
                outlined
                @click="setToday"
              >
                {{ $t('generic.lang_today') }}
              </v-btn>
              <v-btn
                color="grey darken-2"
                fab
                icon
                text
                @click="$refs.calendar.prev()"
              >
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>

              <v-btn
                class="elevation-0 text-black border"
                color="primary"
                fab
                small
                @click="picker = true"
              >
                {{ new Date(requested_date).getDate() }}
              </v-btn>

              <v-btn
                color="grey darken-2"
                fab
                icon
                small
                @click="$refs.calendar.next()"
              >
                <v-icon large> mdi-chevron-right </v-icon>
              </v-btn>
              <strong
                v-if="$refs.calendar"
                class="font-weight-bolder"
              >
                {{ $refs.calendar.title }}
              </strong>
              <v-spacer></v-spacer>
            </div>
          </v-col>
          <v-col cols="12">
            <v-row class="fill-height pa-0 ma-0">
              <v-col class="pa-0 ma-0">
                <div
                  style="height: 0 !important; visibility: hidden !important"
                >
                  <v-calendar
                    ref="calendar"
                    v-model="requested_date"
                    color="primary"
                    type="day"
                  ></v-calendar>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="pt-0 mt-0"
            cols="12"
          >
            <v-row class="pt-0 mt-0">
              <v-col
                cols="12"
                lg="6"
                md="6"
                sm="6"
              >
                <BaseDateInput
                  hide-details
                  type="date"
                  dense
                  outlined
                  v-model="startDate"
                  :label="$t('generic.lang_from')"
                />
              </v-col>
              <v-col
                cols="12"
                lg="6"
                md="6"
                sm="6"
              >
                <BaseDateInput
                  hide-details
                  type="date"
                  dense
                  outlined
                  v-model="endDate"
                  :label="$t('generic.lang_to')"
                />
              </v-col>

              <v-col
                cols="12"
                lg="4"
                md="6"
                sm="12"
              >
                <b-form-select
                  :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                  v-model="type"
                  :label-field="$t('generic.lang_allTypes')"
                  :placeholder="$t('generic.lang_allTypes')"
                  size="md"
                >
                  <b-form-select-option :value="-1">{{
                    $t('generic.lang_allTypes')
                  }}</b-form-select-option>
                  <b-form-select-option :value="0">{{
                    $t('eventbee.lang_entryTicket')
                  }}</b-form-select-option>
                  <b-form-select-option :value="1">{{
                    $t('eventbee.lang_eventTicket')
                  }}</b-form-select-option>
                </b-form-select>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="6"
                sm="12"
              >
                <b-form-select
                  :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                  v-model="group"
                  :label-field="$t('eventbee.lang_eventGroup')"
                  :placeholder="$t('eventbee.lang_eventGroup')"
                  size="md"
                >
                  <b-form-select-option :value="-1"
                    >{{
                      $t('generic.lang_all') +
                      ' ' +
                      $t('eventbee.lang_eventGroups')
                    }}
                  </b-form-select-option>
                  <b-form-select-option
                    v-for="t in this.eventGroups"
                    :key="t.id"
                    :value="t.id"
                  >
                    {{ t.name }}
                  </b-form-select-option>
                </b-form-select>
              </v-col>

              <v-col
                cols="12"
                lg="4"
                md="6"
                sm="12"
              >
                <b-form-select
                  :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                  v-model="provider"
                  :placeholder="$t('generic.lang_provider')"
                  se
                  size="md"
                >
                  <b-form-select-option :value="-1"
                    >{{
                      $t('generic.lang_all') + ' ' + $t('generic.lang_provider')
                    }}
                  </b-form-select-option>
                  <b-form-select-option
                    v-for="t in this.serviceProviders"
                    :key="t.userID"
                    :value="t.userID"
                  >
                    {{ t.fullName }}
                  </b-form-select-option>
                </b-form-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="pa-0"
            cols="12"
          >
            <v-data-table
              dense
              @click:row="setOrder"
              :headers="headers"
              :items="this.reservations"
              :loading="loadData"
              :no-data-text="$t('generic.lang_noEntriesFound')"
            >
              <!--headers-->
              <template v-slot:header.date="{ header }">
                <font-awesome-icon
                  :icon="['fal', 'calendar']"
                  class="text-muted"
                  size="2x"
                  style="font-size: 15px"
                />
                <span class="ml-2">{{ header.text }}</span>
              </template>

              <template v-slot:header.name="{ header }">
                <font-awesome-icon
                  :icon="['fal', 'user']"
                  class="text-muted"
                  size="2x"
                  style="font-size: 15px"
                />
                <span class="ml-2">{{ header.text }}</span>
              </template>

              <template v-slot:header.code="{ header }">
                <font-awesome-icon
                  :icon="['fal', 'hashtag']"
                  class="text-muted"
                  size="2x"
                  style="font-size: 15px"
                />
                <span class="ml-2">{{ header.text }}</span>
              </template>

              <template v-slot:header.person="{ header }">
                <font-awesome-icon
                  :icon="['fal', 'users']"
                  class="text-muted"
                  size="2x"
                  style="font-size: 15px"
                />
                <span class="ml-2">{{ header.text }}</span>
              </template>

              <template v-slot:header.phone="{ header }">
                <font-awesome-icon
                  :icon="['fal', 'phone']"
                  class="text-muted"
                  size="2x"
                  style="font-size: 15px"
                />
                <span class="ml-2">{{ header.text }}</span>
              </template>

              <template v-slot:item.date="{ item }">
                <div class="date">
                  <span class="binds"></span>
                  <span class="month">{{ item.eventDate }}</span>
                  <span class="month">{{ item.date }}</span>
                  <span class="day text-muted font-weight-bolder">
                    <span class="mx-1"
                      ><font-awesome-icon
                        :icon="['fal', 'users']"
                        class="text-muted"
                        size="2x"
                        style="font-size: 16px"
                      />
                      <span
                        class="ml-1"
                        style="font-size: 10px"
                        >{{ item.numberOfPersons }}</span
                      >
                    </span>

                    <span class="mx-1"
                      ><font-awesome-icon
                        :icon="['fal', 'user']"
                        class="success--text"
                        size="2x"
                        style="font-size: 16px"
                      />
                      <span
                        class="ml-1 success--text"
                        style="font-size: 10px"
                        >{{ item.numberOfPersons }}</span
                      >
                    </span>

                    <span class="mx-1"
                      ><font-awesome-icon
                        :icon="['fal', 'keynote']"
                        class="text-muted"
                        size="2x"
                        style="font-size: 16px"
                      />
                    </span>
                  </span>
                </div>
              </template>
              <template v-slot:item.tickets="{ item }">
                <li
                  style="min-width: 150px"
                  v-for="(ticket, i) in item.tickets"
                  :key="i"
                  class="list-unstyled"
                >
                  <strong>{{ ticket.qty + 'x ' }}</strong> {{ ticket.name }} 
                  <strong v-if="ticket.table">{{ ticket.table ? $t('generic.lang_table')+' '+ticket.table:''}}</strong>
                  <strong v-if="ticket.rowNumber">{{ $t('eventbee.lang_row')+'-'+ticket.rowNumber+','+$t('eventbee.lang_seat')+' '+ticket.seatNumber}}</strong>
                </li>
              </template>

              <template v-slot:item.customer="{ item }">
                <div v-if="item.customer && !Array.isArray(item.customer)">
                  <strong  class="primary--text">{{
                      item.customer.first_name + ' ' + item.customer.last_name
                    }}</strong>
                  <br />
                  <font-awesome-icon
                      :icon="['fal', 'phone']"
                      class="text-muted"
                      style="font-size: 15px"
                  />
                  <span  class="text--black ml-1">{{
                      item.customer.telPrivate
                    }}</span>
                </div>
                <strong v-else class="primary--text">
                  {{ $t('generic.lang_table_Tresenverkau') }}
                </strong>

                <br />
                <span class="text--black ml-1 font-weight-bold">{{
                  item.note
                }}</span>

                <div
                  v-if="item.code"
                  class="copy"
                  @click="copy(item.code)"
                >
                  <font-awesome-icon
                    :icon="['fal', 'copy']"
                    class="text-muted"
                    size="2x"
                    style="font-size: 14px"
                  />
                  <span class="font-weight-bold ml-1">{{ item.code }}</span>
                </div>
              </template>

              <template v-slot:item.eventName="{ item }">
                {{ item.eventName }}<br/>
                <strong class="text-muted" v-html="item.attribute"></strong>
              </template>

              <template v-slot:item.group="{ item }">
                <strong>{{ item.group }}</strong>
              </template>

              <template v-slot:item.payment="{ item }">
                <strong>{{ item.payment }}</strong>
              </template>

              <template v-slot:item.total="{ item }">
                <strong>{{ item.total | currency }}</strong>
              </template>
              <template v-slot:item.created_at="{ item }">
                <span v-if="item.created_at">
                  <font-awesome-icon
                    :icon="['fal', 'clock']"
                    class="text-muted"
                    size="2x"
                    style="font-size: 16px"
                  />
                  <span class="font-weight-bold ml-2">{{
                    item.created_at
                  }}</span>
                </span>
              </template>
              <template v-slot:item.canceled_at="{ item }">
                <span v-if="item.canceled_at">
                  <font-awesome-icon
                    :icon="['fal', 'clock']"
                    class="text-muted"
                    size="2x"
                    style="font-size: 16px"
                  />
                  <span class="font-weight-bold ml-2">{{
                    item.canceled_at
                  }}</span>
                </span>
              </template>

              <template v-slot:item.action="{ item }">
                <font-awesome-icon
                  v-if="!item.canceled_at && $store.getters['permissions/checkPermission']('eventbee_edit_order')"
                  :icon="['fal', 'calendar-edit']"
                  class="text-primary actions mx-2"
                  size="2x"
                  style="font-size: 25px"
                  @click.stop="show_edit(item)"
                />

                <font-awesome-icon
                  v-if="!item.canceled_at && $store.getters['permissions/checkPermission']('eventbee_void_order')"
                  :icon="['fal', 'calendar-times']"
                  class="text-danger actions mx-2"
                  size="2x"
                  style="font-size: 25px"
                  @click.stop="cancel(item)"
                />

                <font-awesome-icon
                  v-if="!item.canceled_at"
                  :icon="['fal', 'envelope']"
                  class="text-primary actions mx-2"
                  size="2x"
                  style="font-size: 25px"
                  @click.stop="reSendInvoiceAndTicketsEmail(item.invoiceUUID)"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- email dialog -->

    <v-dialog
      v-model="email_dialog"
      max-width="600px"
      persistent
    >
      <v-form
        ref="emailForm"
        lazy-validation
      >
        <div class="card">
          <div class="card-header">
            {{ $t('generic.lang_email') }}
          </div>

          <div class="card-body">
            <v-row>
              <v-col
                class="pt-0 mt-0 pb-0 mb-0"
                cols="12"
              >
                <v-text-field
                  v-model="email_form.subject"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :rules="[(v) => !!v]"
                  clearable
                  dense
                  :label="$t('settings.lang_mail_subject')"
                  outlined
                  @focus="showTouchKeyboard"
                />
              </v-col>

              <v-col
                class="pt-0 mt-0"
                cols="12"
              >
                <quill-editor
                  v-model="email_form.message"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :options="quillOptions"
                  :rules="[(v) => !!v]"
                  @focus="showTouchKeyboard"
                ></quill-editor>
              </v-col>
            </v-row>
          </div>

          <div class="card-footer text-right pa-1 pr-3">
            <v-spacer />
            <v-btn
              class="ma-1"
              color="error"
              @click="email_dialog = false"
            >
              {{ $t('generic.lang_cancel') }}
            </v-btn>
            <v-btn
              :disabled="email_loader"
              :loading="email_loader"
              class="ma-1"
              color="primary"
              @click="send"
            >
              {{ $t('support.lang_supportSend') }}
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-dialog>

    <!-- date picker dialog -->
    <v-dialog
      v-model="picker"
      max-width="400px"
      scrollable
    >
      <v-card
        align="center"
        class="pa-0 ma-0"
      >
        <v-card-title class="pa-0 px-0 ma-0">
          <v-spacer />
          <v-btn
            icon
            @click="picker = false"
          >
            <v-icon color="error"> close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-date-picker
            v-model="requested_date"
            event-color="green lighten-1"
            width="100%"
          ></v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--    <edit-reservation v-if="this.edit_dialog" v-model="edit_dialog" :default_data="this.selectedEvent"
                          v-bind:dialog="this.edit_dialog"
                          @close_edit="close_edit()"
                          @getEvents="loadRes()"/>-->

    <edit-event-bee-order-dialog
      @orderUpdated="orderUpdated"
      v-model="edit_dialog"
      :order-uuid="this.selectedEvent.uuid"
      v-if="this.selectedEvent && edit_dialog"
    ></edit-event-bee-order-dialog>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        v-if="touchKeyboard.visible"
        id="onScreenKeyboard"
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidthExpanded"
      />
    </div>
    <EventbeeOrderDetailsComponent v-model="showOrderDetailsDialog" :uuid="selectedOrderUUID"
      @updateOrders="loadRes" />
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import mixin from '@/mixins/KeyboardMixIns';

import ReservationCalendar from '@/components/reservation/reservations/ReservationCalendar';
import Calendar from '@/views/accounting/Calendar';
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAt,
  faCalendar,
  faCalendarAlt,
  faCalendarEdit,
  faCalendarTimes,
  faCheck,
  faCheckSquare,
  faClipboard,
  faClock,
  faCopy,
  faEdit,
  faEnvelope,
  faHashtag,
  faInfoSquare,
  faKeynote,
  faPaperPlane,
  faPhone,
  faStickyNote,
  faTrash,
  faUser,
  faUsers,
  faWindowClose,
} from '@fortawesome/pro-light-svg-icons';
import EditReservation from '@/components/reservation/reservations/EditReservation';
import VueHtml2pdf from 'vue-html2pdf';
import EditEventBeeOrderDialog from '@/components/eventbee/EditEventBeeOrderDialog';
import FileSaver from 'file-saver';
import BaseDateInput from "@/components/common/BaseDateInput.vue";
import EventbeeOrderDetailsComponent from "./order-overview/EventbeeOrderDetailsComponent";

library.add(
  faUsers,
  faPaperPlane,
  faClipboard,
  faClock,
  faCalendarAlt,
  faKeynote,
  faAt,
  faCalendar,
  faUser,
  faPhone,
  faCopy,
  faCheckSquare,
  faTrash,
  faInfoSquare,
  faCalendarEdit,
  faCalendarTimes,
  faWindowClose,
  faEnvelope,
  faEdit,
  faStickyNote,
  faCheck,
  faHashtag
);

export default {
  name: 'EventBeeDailyOrdersComponent',
  mixins: [mixin],
  components: {
    EditEventBeeOrderDialog,
    EditReservation,
    ReservationCalendar,
    Calendar,
    quillEditor,
    'font-awesome-icon': FontAwesomeIcon,
    'vue-html2pdf': VueHtml2pdf,
    EventbeeOrderDetailsComponent,
    BaseDateInput
  },
  data() {
    return {
      downloadingPdf: false,
      pdfFIleName: this.$t('generic.lang_reservation'),
      picker: false,
      requested_date: '',
      selectedEvent: null,
      showOrderDetailsDialog: false,
      selectedOrderUUID: null,
      startDate: null,
      endDate: null,
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            [{ font: [] }],
          ],
        },
      },
      search: '',
      sendLoader: false,
      email_form: {
        email: '',
        subject: '',
        message: '',
      },
      email_dialog: false,
      edit_dialog: false,
      email_loader: false,
      loadData: false,
      loading: false,
      loadingEmail: false,
      reservations: [],
      tabs: false,
      selectedOpen: false,
      eventGroups: [],
      serviceProviders: [],
      type: -1,
      group: -1,
      provider: -1,
      status: -1,
    };
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('generic.lang_date'),
          value: 'date',
        },
        {
          text: this.$t('generic.lang_name'),
          value: 'customer',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_event'),
          value: 'eventName',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_group'),
          value: 'group',
        },
        {
          text: this.$t('eventbee.lang_tickets'),
          value: 'tickets',
        },
        {
          text: this.$t('generic.lang_total'),
          value: 'total',
        },
        {
          text: this.$t('accounting.lang_PaymentMethod'),
          value: 'payment',
        },
        {
          text: this.$t('generic.lang_createdAt'),
          value: 'created_at',
        },
        {
          text: this.$t('generic.lang_canceled'),
          value: 'canceled_at',
        },
        {
          text: '',
          value: 'action',
          sortable: false,
        },
      ];
    },
    validTime() {
      if (this.startDate != null && this.endDate != null) {
        return (
          this.endDate,
          moment(this.startDate).unix() < moment(this.endDate).unix()
        );
      }
      return true;
    },
  },

  mounted() {
    this.setToday();
    this.loadRes();
    this.getEventGroups();
    this.getServiceProviders();
  },
  watch: {
    search(val) {
      this.loadRes();
    },
    type(val) {
      this.loadRes();
    },
    group(val) {
      this.loadRes();
    },
    provider(val) {
      this.loadRes();
    },
    requested_date(val) {
      this.endDate = null;
      this.startDate = null;
      this.loadRes();
    },
    startDate(val) {
      this.loadRes();
    },
    endDate(val) {
      this.loadRes();
    },
  },
  methods: {
    setOrder(order) {
      this.selectedOrderUUID = order.uuid;
      this.showOrderDetailsDialog = true;
    },
    reSendInvoiceAndTicketsEmail(invoiceUUID){
      this.loadingEmail = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CASHIER.SUBDOMAIN.GET).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          if(res.data.hasDomains){
            let domain = res.data.domains.find(d=>d.service === 'eventbee')
            if(domain){
              let eventbeeSubDomain = domain.domain;

              this.$swal({
                title: this.$t('eventbee.lang_resendInvoiceAndTickets'),
                text: this.$t('eventbee.lang_resendInvoiceAndTicketsMSG'),
                icon: "warning",
                confirmButtonText: this.$t('generic.lang_yes'),
                cancelButtonText: this.$t('generic.lang_cancel'),
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                  this.loadingEmail = true;
                  let serviceApiAxios = this.axios.create({
                    baseURL: process.env.VUE_APP_SERVICE_API_HOST
                  });

                  delete serviceApiAxios.defaults.headers.common['3POSAPITOKEN'];
                  delete serviceApiAxios.defaults.headers.common['3POSBS'];
                  delete serviceApiAxios.defaults.headers.common['3POSBSID'];
                  serviceApiAxios.post(`eventbee/orders/resend-email/${invoiceUUID}?service_domain=${eventbeeSubDomain.split('.')[0]}`,
                  ).then((res) => {
                    if (res.status === 200) {
                      Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_ActionSuccessful"),
                        color: "success"
                      });

                    } else {
                      Events.$emit("showSnackbar", {
                        message: res.data.msg || this.$t('generic.lang_errorOccurred'),
                        color: "error"
                      });
                    }
                  }).catch(err => {
                    Events.$emit("showSnackbar", {
                      message: err.message,
                      color: "error"
                    });
                  }).finally(() => {
                    this.loadingEmail = false;
                  });
                },
                allowOutsideClick: () => !this.$swal.isLoading,
              });
            }else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_noDomainHasBeenFound'),
                color: "error"
              });
            }
          }else{
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_noDomainHasBeenFound'),
              color: "error"
            });
          }
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingEmail = false;
      })
    },
    orderUpdated() {
      this.edit_dialog = false;
      this.selectedEvent = null;
      this.loadRes();
    },
    generatePDF() {
      this.downloadingPdf = true;
      this.axios
        .post(
          ENDPOINTS.EVENTBEE.ORDER_LIST.DOWNLOAD_PDF,
          {
            day: this.$moment(this.requested_date).format('DD.MM.YYYY'),
            startDate: this.$moment(this.startDate).format('DD.MM.YYYY'),
            endDate: this.$moment(this.endDate).format('DD.MM.YYYY'),
          },
          {
            responseType: 'arraybuffer',
          }
        )
        .then((res) => {
          if (res.status === 200) {
            FileSaver.saveAs(
              new Blob([res.data], {
                type: 'application/pdf',
              }),
              this.$moment(this.requested_date).format('DD.MM.YYYY') +
                '-eventbee.pdf'
            );
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch(() => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.downloadingPdf = false;
        });
    },
    close_edit() {
      this.edit_dialog = false;
      this.selectedEvent = null;
    },
    setToday() {
      let dt = new Date();
      this.requested_date =
        dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getEventGroups() {
      this.loadingGroups = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.EVENTGROUPS.GET, {})
        .then((res) => {
          this.eventGroups = res.data.data;
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingGroups = false;
        });
    },
    getServiceProviders() {
      this.loadingGroups = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.SERVICEPROVIDERS, {})
        .then((res) => {
          this.serviceProviders = res.data.users;
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingGroups = false;
        });
    },
    send() {
      if (!this.$refs.emailForm.validate()) return;
      this.email_loader = true;
      this.axios
        .post(ENDPOINTS.EMAILS.RESERVATIONS.CONTACTS, this.email_form)
        .then((res) => {
          if (res.data.STATUS === 'SUCCESS') {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_success'),
              color: 'success',
            });

            this.email_dialog = false;
            this.$refs.emailForm.reset();
            this.email_form.message = '';
          } else if (res.data.STATUS === 'FAILED') {
            Events.$emit('showSnackbar', {
              message: res.data.msg,
              color: 'error',
            });
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.email_loader = false;
        });
    },
    close(item) {
      let index = this.reservations.indexOf(item);
      item.selectedOpen = false;
      this.reservations[index] = Object.assign({}, item);
      item.selectedOpen = false;
    },
    copy(code) {
      let self = this;
      navigator.clipboard.writeText(code).then(
        function () {
          self.$bvToast.toast(code, {
            title: self.$t('eventbee.lang_ticketCodeCopied'),
            variant: 'info',
            solid: true,
          });
        },
        function (err) {
          this.$bvToast.toast(code, {
            title: self.$t('generic.lang_errorOccurred'),
            variant: 'danger',
            solid: true,
          });
        }
      );
    },
    open_email(item) {
      this.email_form.email = item.email;
      this.close(item);
      this.email_dialog = true;
    },

    cancel(item) {
      this.$swal({
        title: this.$t('generic.lang_cancel'),
        text: this.$t('erp.lang_deleteOrderText'),
        icon: 'warning',
        cancelButtonText: this.$t('generic.lang_cancel'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.loading = true;

          this.axios
            .post(ENDPOINTS.EVENTBEE.ORDERS.CANCEL, {
              uuid: item.uuid,
            })
            .then((res) => {
              if (res.data.status) {
                Events.$emit('showSnackbar', {
                  message: this.$t('generic.lang_success'),
                  color: 'success',
                });
                this.loadRes();
              } else {
                Events.$emit('showSnackbar', {
                  message:
                    res.data?.message ?? this.$t('generic.lang_errorOccurred'),
                  color: 'error',
                });
              }
            })
            .catch((err) => {
              Events.$emit('showSnackbar', {
                message: err.message,
                color: 'error',
              });
            })
            .finally(() => {
              this.loading = false;
            });
        },
      });
    },
    deleteItem(item) {
      this.$swal({
        title: this.$t('generic.lang_delete'),
        text:
          this.$t('generic.lang_deleteConfirmationMSG') +
          ' ' +
          this.$t('generic.lang_order'),
        icon: 'warning',
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.loading = true;
          this.loadData = true;

          this.axios
            .post(ENDPOINTS.RESERVATION.RESERVATIONS.DELETE, item)
            .then((res) => {
              if (res.data.STATUS === 'SUCCESS') {
                Events.$emit('showSnackbar', {
                  message: this.$t('generic.lang_success'),
                  color: 'success',
                });
                this.loadRes();
              } else if (res.data.STATUS === 'FAILED') {
                Events.$emit('showSnackbar', {
                  message: res.data.msg,
                  color: 'error',
                });
              } else {
                Events.$emit('showSnackbar', {
                  message: this.$t('generic.lang_errorOccurred'),
                  color: 'error',
                });
              }
            })
            .catch((err) => {
              Events.$emit('showSnackbar', {
                message: err.message,
                color: 'error',
              });
            })
            .finally(() => {
              this.loading = false;
              this.loadData = false;
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
    },
    loadRes() {
      this.loadData = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.ORDERS.ALL, {
          date: this.requested_date,
          startDate: this.startDate,
          endDate: this.endDate,
          type: this.type,
          groupID: this.group,
          providerID: this.provider,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            this.reservations = [];
            res.data.data.forEach((order) => {
              this.reservations.push({
                date:
                  order.fromTime.substring(0, 5) +
                  ' ' +
                  this.$t('generic.lang_to') +
                  ' ' +
                  order.toTime.substring(0, 5),
                customer: order.customerData ?? {},
                eventDate: order.eventDate,
                eventName: order.eventName,
                group: order.groupName,
                attribute: order.attribute,
                tickets: order.tickets,
                total: order.total,
                payment: order.paymentType,
                numberOfPersons: order.numberOfPersons,
                created_at: order.created,
                invoiceUUID: order.invoiceUUID,
                canceled_at:
                  order.canceled > 0
                    ? this.$moment.unix(order.canceled).format('DD.MM.YY HH:MM')
                    : null,
                code: order.code,
                note: order.note,
                uuid: order.uuid,
              });
            });
            this.$emit('reloadStatistics');
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loadData = false;
        });
    },
    show_edit(item) {
      this.selectedEvent = Object.assign({}, item);
      this.edit_dialog = true;
    },
    saveReservation(item) {
      this.loading = true;
      this.loadData = true;

      this.axios
        .post(ENDPOINTS.RESERVATION.RESERVATIONS.UPDATE, {
          uuid: item.uuid,
          file: item.file,
          date: item.date,
          time: item.time,
          person: item.person,
          customer_id: item.customer_id,
          customer: item.customer,
          customer_note: item.customer_note,
          email: item.email,
          phone: item.resType,
          end_at: item.end_at,
          note: item.note,
          resType: item.resType,
          internal_note: item.internal_note,
          confirmed: item.confirmed,
          selected: item.selected,
          canceled: item.canceled,
          customer_changed: item.customer_changed,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.STATUS === 'SUCCESS') {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_success'),
              color: 'success',
            });
            this.loadRes();
          } else if (res.data.STATUS === 'FAILED') {
            Events.$emit('showSnackbar', {
              message: res.data.msg,
              color: 'error',
            });
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
          this.loadData = false;
        });
    },
  },
};
</script>

<style scoped>
.date {
  display: block;
  width: 160px;
  height: 90px;
  margin: 1px 1px 1px 1px;
  background: #fff;
  text-align: center;
  font-family: 'Helvetica', sans-serif;
  position: relative;
}

.date .binds {
  position: absolute;
  height: 15px;
  width: 150px;
  background: transparent;
  border: 2px solid #999;
  border-width: 0 5px;
  top: -6px;
  left: 0;
  right: 0;
  margin: auto;
}

.date .month {
  background: #555;
  display: block;
  padding: 4px 0;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 2px solid #333;
  box-shadow: inset 0 -1px 0 0 #666;
}

.date .day {
  display: block;
  margin: 0;
  padding: 5px 0;
  height: 35px;
  box-shadow: 0 0 3px #ccc;
  position: relative;
}

.date .day .time {
  font-size: 20px;
}

.date .day::after {
  content: '';
  display: block;
  height: 100%;
  width: 96%;
  position: absolute;
  top: 3px;
  left: 2%;
  z-index: -1;
  box-shadow: 0 0 3px #868484;
}

.date .day::before {
  content: '';
  display: block;
  height: 100%;
  width: 95%;
  position: absolute;
  top: 6px;
  left: 0%;
  z-index: -1;
  box-shadow: 0 0 3px #ccc;
}

.copy,
.actions {
  cursor: pointer;
}

.v-toolbar__content {
  padding: 0 !important;
}

#pdfcontainer {
  width: 102% !important;
  margin-left: -13px !important;
  padding: 5px !important;
  padding-top: 0 !important;
}
</style>
